import $ from 'jquery';

const Isotope = require('isotope-layout');

const filter1 = $('.js-filter1')

if (filter1.length) {
	const iso = new Isotope('.js-filter1', {
		itemSelector: '.js-filter1-item',
	});

	$('.js-filter1-btn').on('click', function () {
		const data = $(this).data('filter');

		iso.arrange({
			filter: data,
		});

		$(this)
			.parents('.js-filter1-btns')
			.find('.js-filter1-btn')
			.removeClass('is-active');
		$(this).addClass('is-active');
	});
}


