import $ from 'jquery';
//ローディング要素
var loadingImg = $('.loading');

//ローディング消して、コンテンツ出現
function loadedPage() {
	function contFadeIn() {
		$('.l-wrapper').addClass('fade-in');
	}

	if (loadingImg.length) {
		loadingImg.addClass('is-hidden');
		contFadeIn();

		// setTimeout(() => {
		// 	loadingImg.addClass('is-hidden');
		// }, 1500);
	}
}

//セッション確認・分岐
if (!sessionStorage.getItem('visited')) {
	//初回
	loadingImg.addClass('is-show');
	sessionStorage.setItem('visited', 'first');

	var timer;
	setTimeout(timerCount, 1500);
	function timerCount() {
		timer = true;
	}

	//loadされたら
	window.addEventListener('load', function () {
		if (timer == true) {
			setTimeout(loadedPage, 200);
		} else {
			setTimeout(loadedPage, 1700);
		}
	});
} else {
	//2回目以降
	//loadされたら
	window.addEventListener('load', function () {
		setTimeout(loadedPage, 100);
	});
}
