
import $ from 'jquery';
$(function () {
	function findMaxHeight(elements) {
		let maxHeight = 0
		elements.each(function () {
			let elementHeight = $(this).parents('.js-maxHeight').find('.js-maxHeightText').height()
			if (elementHeight > maxHeight) {
				maxHeight = elementHeight
			}
		});
		return maxHeight
	}
	function updateMaxHeight() {
		let windowWidth = $(window).width()
		let listItems = $(".js-maxHeightItemText")
		let maxItemHeight = findMaxHeight(listItems)
		if (windowWidth > 768) {
			listItems.height(maxItemHeight)
		} else {
			listItems.height('auto');
		}
	}
	updateMaxHeight()

	$(window).on('resize', updateMaxHeight)
});
