import $ from 'jquery';

$('.js-title').each(function () {
	let text = $(this).html();
	text = text.split('');
	let content = '';
	for (let i = 0, len = text.length; i < len; i++) {
		if (text[i] === ' ') {
			text[i] = '&nbsp'
		};
		content += `
				<span class="js-title-character" style="--char-index: ${i};">
					<span class="js-title-inner">${text[i]}</span>
				</span>
			`;
	}

	$(this).html(content);
});

function activeAnimationScroll(className) {
	if ($(className).length) {
		const hWindow = $(window).innerHeight() / 7;

		$(className).each(function () {
			const _this = $(this);

			$(window).on('scroll load', function () {
				if (
					$(window).scrollTop() >
					_this.offset().top - $(window).innerHeight() + hWindow
				) {
					_this.addClass('is-active');
				}
			});
		});
	}
}

activeAnimationScroll('.js-title');
activeAnimationScroll('.js-fadeIn');
