import $ from 'jquery';

let scrollPosi;
let $bodyHtml = $('body,html');
let $html = $('html');
let $body = $('body');

function bodyFix() {
	scrollPosi = $(window).scrollTop();
	$body.css({
		position: 'fixed',
		width: '100%',
		'z-index': '1',
		top: -scrollPosi,
	});
}

function bodyFixReset() {
	$body.css({
		position: 'relative',
		width: 'auto',
		top: '0',
	});
	$bodyHtml.scrollTop(scrollPosi);
}

export { bodyFix, bodyFixReset };
