import $ from 'jquery';

$(function () {
	const listHover = $('.js-list-hover')
	const block6Style1 = $('.c-block6--style1')

	function activeBlock6() {
		const hW = $(window).innerHeight()
		const hWHalf = $(window).innerHeight() / 2

		if ($(window).scrollTop() > (hW + hWHalf)) {
			block6Style1.addClass('is-show')

		} else {
			block6Style1.removeClass('is-show')
		}

		block6Style1.css({
			'height': $(window).innerHeight() - $('.c-header').innerHeight(),
		})

		if ($(window).outerWidth() < 769) {
			$('.p-top1').css({
				'margin-bottom': block6Style1.innerHeight()
			})

			if ($(window).outerWidth() > $(window).innerHeight()) {
				block6Style1.addClass('is-landscap')

			} else {
				block6Style1.removeClass('is-landscap')
			}

		} else {
			$('.p-top1').removeAttr('style')
		}
	}

	activeBlock6()

	$(window).on('scroll resize', function () {
		activeBlock6()
	})

	if (listHover.length) {
		if ($(window).outerWidth() > 768) {
			listHover.each(function () {
				const _thisList = $(this)
				const items = _thisList.find('.js-list-item li')
				const listitem = _thisList.find('.js-list-item')
				const imgs = _thisList.find('.js-list-img li')
				const mainimg = _thisList.find('.js-mainimg')

				items.on('mouseover', function () {
					imgs.removeClass('is-active')
					$(imgs.eq($(this).index())).length && $(imgs.eq($(this).index())).addClass('is-active')
				})

				listitem.on('mouseover', function () {
					mainimg && mainimg.addClass('is-active')
				})

				items.on('mouseleave', function () {
					imgs.removeClass('is-active')
				})

				listitem.on('mouseleave', function () {
					mainimg && mainimg.removeClass('is-active')
				})
			})
		}
	}
})
