import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const slider1 = new Swiper('.js-slider01 .c-slider01__slide', {
	autoplay: true,
	loop: true,
	slidesPerView: 1,
	spaceBetween: 20,
	breakpoints: {
		769: {
			slidesPerView: 4,
			spaceBetween: 20,
		},
		1200: {
			slidesPerView: 4,
			spaceBetween: 40,
		},
	},
	navigation: {
		nextEl: '.js-slider01 .c-slider01__next',
		prevEl: '.js-slider01 .c-slider01__prev',
	},
});

const sliderMV = new Swiper('.js-slideMV .c-mv__wrap', {
	// autoplay: true,
	loop: true,
	slidesPerView: 1,
	spaceBetween: 0,
	effect: 'creative',
	creativeEffect: {
		prev: {
			// will set `translateZ(-400px)` on previous slides
			translate: [0, 0, -400],
		},
		next: {
			// will set `translateX(100%)` on next slides
			translate: ['100%', 0, 0],
		},
	},
	pagination: {
		el: '.swiper-pagination',
	},
});

// Carousel
$(function () {
	const carousel = $('.js-carousel');
	const isLoading = $('.c-mv__slide__item.is-loading');

	if (isLoading.length) {
		isLoading.removeClass('is-loading');
	}

	if (carousel.length) {
		carousel.each(function () {
			let timeoutSlide;
			let pagCount = null;
			const _thisSlide = $(this);
			const slideItem = $('.js-carousel-slide > li', _thisSlide);
			const paging = $('.js-carousel-paging', _thisSlide);
			const resetClass = function () {
				paging.removeClass('is-active');
				slideItem.removeClass('is-active is-next');
			}

			if (paging.length && slideItem.length && paging.length === slideItem.length) {
				const activePageItem = function (idSlide) {
					resetClass();
					timeoutSlide && clearTimeout(timeoutSlide);

					let idNext = ((idSlide + 1) === paging.length) ? 0 : idSlide + 1;

					$(paging[idSlide]).addClass('is-active');
					$(slideItem[idSlide]).addClass('is-active');
					$(slideItem[idNext]).addClass('is-next');

					pagCount = idSlide;
					timeoutSlide = setTimeout(function () {
						activePageItem(((pagCount + 1) === paging.length) ? 0 : pagCount + 1);
					}, 14000);
				}

				activePageItem(0);
			}

			function activePageItem111(idSlide) {
				timeoutSlide && clearTimeout(timeoutSlide);

				if (idSlide >= 0) {
					pagCount = idSlide;
				}

				if (pagCount < paging.length) {
					resetClass();

					paging.removeClass('is-active');
					slideItem.removeClass('is-active is-next');

					$(paging[pagCount]).addClass('is-active');
					$(slideItem[pagCount]).addClass('is-active');
					$(slideItem[pagCount + 1]).addClass('is-next');

					if ((pagCount + 1) === paging.length) {
						$(slideItem[0]).addClass('is-next');
					}

					timeoutSlide = setTimeout(() => {
						pagCount++

						if (pagCount === paging.length) {
							pagCount = 0
						}

						activePageItem(pagCount)
					}, 14000);
				}
			}

			function clickPageItem111(idSlide) {
				timeoutSlide && clearTimeout(timeoutSlide);

				if (idSlide >= 0) {
					pagCount = idSlide;
				}

				if (pagCount < paging.length) {
					resetClass();

				}
			}

			// activePageItem(0);
			// paging.on('click', function () {
			// 	clickPageItem($(this).index());
			// });
		});
	}
})
