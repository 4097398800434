
import $ from 'jquery';

$('a[href*=\\#]:not([href=\\#])').click(function () {
	if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
		&& location.hostname == this.hostname) {
		var $target = $(this.hash);
		$target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
		var offsetTop = $(".c-header").height();
		if ($target.length) {
			var targetOffset = $target.offset().top - offsetTop;
			$('html,body').animate({ scrollTop: targetOffset }, 1000);
			return false;
		}
	}
});


$(window).on('load scroll resize orientationchange', function () {
	if ($('.js-positionFooter').length) {
		let hsec = $('.js-positionFooter').height()
		let positionBtn = $('.js-positionFooter').offset().top
		let hScroll = $(window).scrollTop() + $(window).outerHeight()
		if (hScroll > positionBtn) {
			$('.js-fixedScroll').addClass('is-abs')
		} else {
			$('.js-fixedScroll').removeClass('is-abs')
		}
	}
})


// click load scroll to id section
let target = window.location.hash;
target = target.replace('#', '');
window.location.hash = '';
$(window).on('load', function () {
	if (target) {
		$('html, body').animate(
			{
				scrollTop: $('#' + target).offset().top - $('.c-header').outerHeight(),
			},
			1000,
			'swing',
			function () { },
		);
	}
});
