import Scrollbar from 'smooth-scrollbar';
Scrollbar.initAll({
	alwaysShowTracks: true,
	thumbMinSize: 0,
	container: {
		width: 6,
		height: 6,
	},
	content: {
		width: 6,
		height: 6,
	},
});
