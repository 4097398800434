import $ from 'jquery';

$(function () {
	$('.c-form1__right').each(function () {
		const error = $(this).find('.error');
		if (error.length) {
			$(this).addClass('is-error');
		}
	});
});
