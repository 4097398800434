// import $ from 'jquery';
import '../Components/_Header.js';
import Anchor from '../Components/_Anchor.js';
import '../Components/_Loading.js';
import '../Components/_Slider.js';
import '../Components/_Text.js';
import '../Components/_Animation.js';
import '../Components/_Accordion.js';
import '../Components/_Maxheight.js';
import '../Components/_Form';
import '../Components/_Other.js';
import '../Components/_ScrollBar.js';
import '../Components/_Filter.js';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
