import $ from 'jquery';
import { bodyFix, bodyFixReset } from './_BodyFixed.js';

let $window = $(window);

/* ======================================
open menu content PC
====================================== */
$(function () {
	$('.js-header-menu').on('click', function () {
		$(this).toggleClass('is-open');
		$('.js-hamburger').toggleClass('is-open');
		$('.js-header-content').toggleClass('is-open');
		if ($('.js-header-content').hasClass('is-open')) {
			bodyFix();
		} else {
			bodyFixReset();
		}
	});

	$('.js-submenu').on('click', function () {
		$(this).toggleClass('is-open');
		$(this).parent().next('.js-submenu-content').slideToggle();
	});
});

function resetHeader() {
	$('.js-header-menu').removeClass('is-open');
	$('.js-hamburger').removeClass('is-open');
	$('.js-header-content').removeClass('is-open');
	$('body').removeAttr('style');
}

// Detect ipad
function iOs() {
	return [
		'iPad Simulator',
		'iPhone Simulator',
		'iPhone',
		'iPad',
	].includes(navigator.platform)
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function handleHeightofMv() {
	if ($('.js-carousel').length) {
		if (iOs()) {
			$('.js-carousel').css('height', $(window).innerHeight() - $('.c-header').innerHeight())

		} else {
			$('.js-carousel').removeAttr('style')
		}
	}
}

$(function () {
	handleHeightofMv()
})

$(window).on('resize', function () {
	handleHeightofMv()
})
