import $ from 'jquery';

// open first item by default
if (!$('main').hasClass('p-business')) {
	$('.js-accordion-openFirst:first-child .js-accordion-heading').addClass('is-open');
	$('.js-accordion-openFirst:first-child .js-accordion-content').show();
}

$('.js-accordion').each(function () {
	let _this = $(this);

	$('.js-accordion-heading', _this).on('click', function (e) {
		e.preventDefault();
		$(this).toggleClass('is-open');
		$(this).next().slideToggle();
	});
});

// c-list22
$('.js-showMore').on('click', function (e) {
	$(this).toggleClass('is-open');
	$(this)
		.parents('.js-showMoreBody')
		.find('.js-showMoreContent')
		.stop()
		.slideToggle(300);
	if ($(this).hasClass('is-open')) {
		$(this).find('.js-showMoreTxt').text('閉じる');
	} else {
		$(this).find('.js-showMoreTxt').text('解決事例を見る');
	}
});
